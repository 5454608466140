import Pioneer from '../home/Pioneer.svg';
import Experienced from '../home/Experienced.svg';
import WorkWithRigor from '../home/WorkWithRigor.svg';
// import Pioneer from './home/Pioneer.svg';

const data = [
    {
        review: 'What Clients Say',
        pic: '',
        name: '',
        backgroundColor: 'transparent',
        height: 2,
        width: '100%',
        cols: 1,
        rows: 1
    },
    {
        review: 'Dr. Malcolm  has shown me that he has real concern for his patients. If you need dermatology care, Skin Care Clinic is as good as it gets.',
        pic: Pioneer,
        name: 'BOB PETERSON',
        backgroundColor: '#e1f6ff',
        height: 360,
        width: 550,
        cols: 1,
        rows: 4
    },
    {
        review: 'I go there regularly for my psoriasis and have always had wonderful customer services. The staff at the clinic were all very friendly and professional and I love working with Dr. Smith.',
        pic: Experienced,
        name: 'BOB PETERSON',
        backgroundColor: '#b3e5fc',
        height: 360,
        width: 550,
        cols: 1,
        rows: 4
    },
    {
        review: 'I couldn\'t be happier with the result. I have my skin and my confidence back! Everyone in Skin Care Clinic have been so efficient and friendly, making me feel very comfortable.',
        pic: WorkWithRigor,
        name: 'BOB PETERSON',
        backgroundColor: '#b3e5fc',
        height: 360,
        width: 550,
        cols: 1,
        rows: 4
    },
    {
        review: 'Dr. Black  has shown me that he has real concern for his patients. If you need dermatology care, Skin Care Clinic is as good as it gets.',
        pic: Pioneer,
        name: 'BOB PETERSON',
        backgroundColor: '#e1f6ff',
        height: 360,
        width: 550,
        cols: 1,
        rows: 4
    },
    {
        review: '',
        pic: '',
        name: '',
        backgroundColor: 'transparent',
        height: 2,
        width: '100%',
        cols: 1,
        rows: 1
    },
]

export default data;
