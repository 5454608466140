import React from 'react';
import { Marker, TileLayer, MapContainer, Popup } from "react-leaflet";
import { Icon } from 'leaflet';

export const icon = new Icon({
    iconUrl: "https://i.ibb.co/0Db22t6/iconfinder-Facebook-UI-07-2344289.png",
    iconSize: [70, 70]
});
// import './styles.css';

// import {
//     MapContainer,
//     TileLayer,
//     Marker,
//     // Popup
// } from 'https://cdn.esm.sh/react-leaflet';
// import "leaflet/dist/leaflet.css";


// import "leaflet/dist/leaflet.css";
// import InfoWindow from './InfoWindow';
// import { Icon } from 'leaflet';

// export const icon = new Icon({
//     iconUrl: "https://i.ibb.co/0Db22t6/iconfinder-Facebook-UI-07-2344289.png",
//     iconSize: [70, 70]
// });

const position1 = [22.685298430822094, 90.36030650053448];
const position2 = [22.697147422604136, 90.36814490859545];

const Map = () => {

    return (
        <div
            style={{

            }}
        >
            <MapContainer center={position1} zoom={13} scrollWheelZoom={false}>
                <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker position={position1}
                    icon={icon}
                >
                    <Popup>
                        <b>Skinfinity</b>
                        <br />3rd Floor, Fayeez Shatayu(Near YWCA School), Police Line Road, Barishal
                    </Popup>
                </Marker>
                <Marker position={position2}
                    icon={icon}
                >
                    <Popup>
                        <b>Medinova Medical Services</b><br />Girzamoholla, Barishal
                    </Popup>
                </Marker>
            </MapContainer>
        </div>
    );
};

export default Map;