import React from 'react';
import {
    Link
} from '@mui/material';
import {
    makeStyles
} from '@mui/styles';
import {
    useTheme
} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Banner from '../banner';
import Logo from '../../assets/logo.png';

const useStyles = makeStyles(
    theme => (
        {
            div: {
                height: 'auto',
                maxWidth: '100%',
                overflow: 'hidden',
                backgroundColor: theme.palette.secondary.dark,
                paddingBottom: theme.spacing(3),
                paddingTop: theme.spacing(3),
                [theme.breakpoints.down('md')]: {
                    padding: theme.spacing(2),
                },
            }
        }
    )
);

const Home = ({
    id
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <div
            className={classes.div}
            align={matches ? 'center' : 'left'}
            id={id}
        >
            <img
                src={Logo}
                alt="Skinfinity"
                height='60'
                width='150'
            />
            <Banner
                title={`Skin, Hair & Laser Centre`}
                variant="h6"
                style={{
                    padding: 3,
                    color: '#ffffff'
                }}
                align='inherit'
                gutterBottom
            />
            <Banner
                title={`Copyrights ©Skinfinity 2022. All Rights Reserved.`}
                variant="subtitle1"
                style={{
                    padding: 3,
                    color: '#ffffff'
                }}
                align='inherit'
                gutterBottom
            />
            <Banner
                title={`Developed By Aubichol Intelligent Technologies`}
                component={Link}
                variant="subtitle1"
                style={{
                    // padding: '3px 10px',
                    color: '#ffffff',
                }}
                align='inherit'
                href={`http://www.aubichol.com/`}
                gutterBottom
            />
        </div>
    )
};

export default Home;
