import React from 'react';
import {
	Divider
} from '@mui/material';
// import styled, {
// 	keyframes
// } from 'styled-components';
import ScrollAnimation from 'react-animate-on-scroll';

import Appbar from '../appbar';
import Home from '../home';
//import Service from '../service';
import Service from '../Service/index';
// import Appointment from '../appointment';
import Contact from '../contact';
import Doctor from '../doctor';
import Reviews from '../reviews';
//import Cover from '../../assets/HomeCover1.jpg'
//import Cover from "../../assets/b4.jpg"
//import Cover1 from "../../assets/background1.jpg"
//import Cover2 from "../../assets/background2.jpg"
//import Cover3 from "../../assets/background3.jpg"
import CoverTest from "../../assets/flower1.jpg"
import Footer from '../footer';
import HomeTwo from '../home/Home';
import Header from '../Welcome/index';

const Layout = () => {
	document.title = "Skinfinity";
	// const FadeInLeft = styled.div`animation: 2s ${keyframes`${fadeInLeft}`} 1`;
	// const FadeInRight = styled.div`animation: 2s ${keyframes`${fadeInRight}`} 1`;

	return (
		<div style={{
			// overflowX: 'hidden',
			// backgroundColor: 'rgb(160,204,246)',
			// background: 'radial-gradient(circle, rgtba(160,204,246,1) 0%, rgba(242,245,250,1) 100%)',
		}}>
			
			
			<Appbar />

			<div
				style={{
					backgroundImage: `url(${CoverTest})`,
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					//marginBottom:'1rem',
					//height:'100vh'
					minHeight:'50vh',
					
				}}
			>
				<Header/>
				
				
				<Doctor
				id="doctor"
				/>
				
			</div>

			<div
			style={{
					//backgroundImage: `url(${CoverTest})`,
					backgroundColor:'#C4DCE0',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'cover',
					minHeight:'50vh'
				}}>

				<ScrollAnimation animateIn="fadeIn"
					delay={1000}
					animateOnce={true}
					duration={3}
				>
					<HomeTwo />
			</ScrollAnimation>	
			</div>

            <div
			style={{
					//backgroundImage: `url(${CoverTest})`,
					//backgroundColor:'#F7F5EB',
					//backgroundColor:'#E6E3E1',
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'contain',
					paddingBottom:'2rem',
					//paddingTop:'2rem'
					minHeight:'50vh'
				}}>

				
			<ScrollAnimation animateIn="fadeIn"
					delay={1000}
					animateOnce={true}
					duration={3}
				>
					<Service
				
				     />
				</ScrollAnimation>
			</div>

			{/* <Divider
				variant="middle"
				style={{
					width: '70%',
					margin: '70px auto',
				}}
			/> */}

			{/* <ScrollAnimation
				animateIn="fadeInRight"
				duration={3}
			> */}
			
			{/* </ScrollAnimation> */}
			{/* <Divider
				variant="middle"
				style={{
					width: '70%',
					margin: '70px auto',
				}}
			/> */}

			{/* <ScrollAnimation
				animateIn="fadeInLeft"
				duration={3}
			> 
			<Service
				
			/>*/}
			{/* </ScrollAnimation > */}
			{/* me <Divider
				variant="middle"
				style={{
					width: '70%',
					margin: '70px auto',
				}}
			/>*/}

			{/* <ScrollAnimation
				animateIn="fadeInRight"
				duration={3}
			> */}
			<Reviews
				id="reviews"
			/>
			{/* </ScrollAnimation> 
			<Divider
				variant="middle"
				style={{
					width: '70%',
					margin: '70px auto',
				}}
			/>*/}

			{/* <FadeInRight>
				<Appointment
					id="appointment"
				/>
			</FadeInRight>
			<Divider
				variant="middle"
				style={{
					width: '70%',
					margin: '70px auto',
				}}
			/> */}

			{/* <ScrollAnimation
				animateIn="fadeInLeft"
				duration={3}
			> */}
			<Contact
				id="contact"
			/>
			{/* </ScrollAnimation> */}

			{/* <Divider
				variant="middle"
				style={{
					width: '70%',
					margin: '70px auto',
				}}
			/> */}
			<Footer />
		</div>
	)
};

export default Layout;