import React from 'react';
import {
    makeStyles,

} from '@mui/styles';
import ScrollAnimation from 'react-animate-on-scroll';
import Doctor from '../../assets/doctor.png';
import Banner from '../banner';
import {
    Avatar,
    Grid,
    // useMediaQuery,
    useTheme,
    Paper,
    Chip
} from '@mui/material';
//import Cover from '../../assets/cover.png'
//import Cover from '../../assets/cover.png'
import Cover from '../../assets/background2.jpg'
const useStyles = makeStyles(
    theme => (
        {
            div: {
                // marginTop: theme.spacing(2),
               //minHeight: '550px',
                height: 'auto',
                maxWidth: '90%',
                marginInline:'auto',
                overflow: 'hidden',
                paddingBottom:'2rem', 
                
                backgroundImage: `url(${Cover})`,
                backgroundColor:'white',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                // marginTop: theme.spacing(5),
                marginBottom: theme.spacing(5),
                '& .MuiChip-label': {
                    wordWrap: 'break-word',
                    // wordBreak: 'break-word',
                    whiteSpace: 'break-spaces',
                },
                [theme.breakpoints.down('sm')]: {
                    // width: '100%',
                    // margin: theme.spacing(1)
                },
                '& .MuiGrid-root': {
                    // width: '100%',
                    // marginRight: 0
                }
            },
            paper: {
                width: '90%',
               
                // padding: theme.spacing(1),
                marginInline:'auto',
                [theme.breakpoints.down('lg')]: {
                    // width: '100%',
                    // padding: theme.spacing(3)
                },
                [theme.breakpoints.down('md')]: {
                    width: '95%',
                    margin: '30px auto'
                },

                backgroundColor: theme.palette.primary.contrastText,

                // opacity: '0.9'
            },
            imageBackdrop: {
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                backgroundColor: theme.palette.secondary.main,
                opacity: 0.3,
                transition: theme.transitions.create('opacity'),
            },
        }
    )
);

const Home = ({
    id
}) => {
    const classes = useStyles();
    const theme = useTheme();
    // const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <div
            className={classes.div}
            align='center'
            id={id}
        >
            <ScrollAnimation
                animateIn="fadeIn"
                duration={3}
                animateOnce={true}
            >
                <Banner
                    title='Our Doctor'
                    variant="h3"
                    style={{
                        fontWeight: 900,
                        color: theme.palette.primary.dark,
                        padding: theme.spacing(2)
                    }}
                    gutterBottom
                />
            </ScrollAnimation>
            <ScrollAnimation
                animateIn="fadeIn"
                duration={3}
                delay={800}
                animateOnce={true}
            >
                <Paper className={classes.paper} elevation={1}>
                    {/* <span
                    className={classes.imageBackdrop}
                /> */}
                    <Grid container
                        // spacing={(matches && 2) || 0}
                        style={{
                            // opacity: 1
                            //backgroundColor: '#e1f6ff',
                            backgroundColor: 'white',
                            
                        }}
                    >
                        <Grid item>
                            <Avatar
                                alt="Dr Rezwan Kaiser"
                                src={Doctor}
                                sx={{
                                    width: 300, height: 300, margin: 5,
                                    [theme.breakpoints.down('sm')]: {
                                        margin: 2,
                                        width: 200,
                                        height: 200
                                    },
                                    // borderWidth: 0.5,
                                    // borderStyle: 'solid',
                                    // borderColor: theme.palette.primary.dark
                                }}
                            />
                        </Grid>
                        <Grid item xs={12} sm container>
                            <Grid item xs container direction="row"
                                justifyContent='center'
                                alignItems='center'
                            // spacing={2}
                            >
                                <Grid item xs
                                    justify='center'
                                // sx={{
                                //     verticalAlign: 'middle'
                                // }}
                                >
                                    <Banner
                                        title='Dr. Md. Rezwan Kaiser'
                                        variant="h5"
                                        sx={{
                                            fontWeight: 900,
                                            color: theme.palette.primary.dark,
                                            padding: 0,
                                            [theme.breakpoints.down('md')]: {
                                                marginLeft: theme.spacing(1)
                                            },
                                            // paddingLeft: matches && 15
                                        }}
                                        // align={(matches && 'left') || 'center'}
                                        align='left'
                                    // gutterBottom
                                    />
                                    <Banner
                                        title='Dermatologist'
                                        variant="subtitle1"
                                        sx={{
                                            // fontWeight: 900,
                                            color: theme.palette.primary.dark,
                                            fontSize: 20,
                                            padding: 0,
                                            [theme.breakpoints.down('md')]: {
                                                marginLeft: theme.spacing(1)
                                            },
                                            // paddingLeft: matches && 15
                                        }}
                                        // align={(matches && 'left') || 'center'}
                                        align='left'
                                        gutterBottom
                                    />
                                    <Banner
                                        title={`MBBS(DU), BCS(Heath), DD(Thailand)`}
                                        variant="subtitle1"
                                        sx={{
                                            marginTop: 2,
                                            padding: 0,
                                            [theme.breakpoints.down('md')]: {
                                                marginLeft: theme.spacing(1)
                                            },
                                            // paddingLeft: matches && 15
                                        }}
                                        // align={(matches && 'left') || 'center'}
                                        align='left'
                                    // gutterBottom
                                    />
                                    <Banner
                                        title={`Sher-E-Bangla Medical College, Barishal`}
                                        variant="subtitle1"
                                        sx={{
                                            padding: 0,
                                            [theme.breakpoints.down('md')]: {
                                                marginLeft: theme.spacing(1)
                                            },
                                            // paddingLeft: matches && 15
                                        }}
                                        align='left'
                                        // align={(matches && 'left') || 'center'}
                                        gutterBottom
                                    />
                                    <Banner
                                        // title={`Skin, STI, Sexual Medicine, Allergy & Skin Laser Surgery Specialist`}
                                        variant="h6"
                                        title='Specialty:'
                                        sx={{
                                            padding: 0,
                                            color: theme.palette.primary.dark,
                                            fontSize: 20,
                                            fontWeight: 900,
                                            [theme.breakpoints.down('md')]: {
                                                marginLeft: theme.spacing(1)
                                            },
                                            // paddingLeft: matches && 15
                                        }}
                                        align='left'
                                        // align={(matches && 'left') || 'center'}
                                        gutterBottom
                                    />
                                    <div
                                        align='left'
                                    >
                                        <Chip label='Skin' variant="outlined" size='medium'
                                            sx={{
                                                backgroundColor: theme.palette.primary.contrastText,
                                                color: theme.palette.primary.dark,
                                                border: theme.palette.primary.dark,
                                                borderWidth: 2,
                                                fontSize: 15,
                                                paddingLeft: 3,
                                                paddingRight: 3,
                                                paddingTop: 2,
                                                paddingBottom: 2,
                                                fontWeight: 700,
                                                margin: 1,
                                                [theme.breakpoints.down('sm')]: {
                                                    paddingLeft: 1,
                                                    paddingRight: 1,
                                                },
                                            }}
                                        />
                                        <Chip label='STI' variant="outlined"
                                            sx={{
                                                backgroundColor: theme.palette.primary.contrastText,
                                                color: theme.palette.primary.dark,
                                                border: theme.palette.primary.dark,
                                                borderWidth: 2,
                                                fontSize: 15,
                                                paddingLeft: 3,
                                                paddingRight: 3,
                                                paddingTop: 2,
                                                paddingBottom: 2,
                                                margin: 1,
                                                fontWeight: 700,
                                                [theme.breakpoints.down('sm')]: {
                                                    paddingLeft: 1,
                                                    paddingRight: 1,
                                                },
                                            }}
                                        />
                                        <Chip label='Sexual Medicine' variant="outlined"
                                            sx={{
                                                backgroundColor: theme.palette.primary.contrastText,
                                                color: theme.palette.primary.dark,
                                                border: theme.palette.primary.dark,
                                                borderWidth: 2,
                                                fontSize: 15,
                                                paddingLeft: 3,
                                                paddingRight: 3,
                                                paddingTop: 2,
                                                paddingBottom: 2,
                                                margin: 1,
                                                fontWeight: 700,
                                                [theme.breakpoints.down('sm')]: {
                                                    paddingLeft: 1,
                                                    paddingRight: 1,
                                                },
                                            }}
                                        />
                                        <Chip label={`Allergy & Skin Laser Surgery Specialist`}
                                            sx={{
                                                backgroundColor: theme.palette.primary.contrastText,
                                                color: theme.palette.primary.dark,
                                                border: theme.palette.primary.dark,
                                                borderWidth: 2,
                                                fontSize: 15,
                                                paddingLeft: 3,
                                                paddingRight: 3,
                                                paddingTop: 2,
                                                paddingBottom: 2,
                                                margin: 1,
                                                fontWeight: 700,
                                                // height: 'auto',

                                                [theme.breakpoints.down('sm')]: {
                                                    paddingLeft: 1,
                                                    paddingRight: 1,
                                                },
                                                [theme.breakpoints.down('xs')]: {
                                                    height: 'auto'
                                                },
                                            }}
                                            variant="outlined" />
                                    </div>
                                    {/* <Button
                                    variant='outlined'
                                    disabled
                                    sx={{
                                        color: theme.palette.primary.dark
                                    }}
                                >
                                    Skin
                                </Button> */}
                                </Grid>
                                <Grid item>

                                    {/* <Typography variant="body2" style={{ cursor: 'pointer' }}>
                                    Remove
                                </Typography> */}
                                </Grid>
                            </Grid>
                            {/* <Grid item>
                            <Typography variant="subtitle1">$19.00</Typography>
                        </Grid> */}
                        </Grid>
                    </Grid>
                </Paper>

                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                >
                    <Grid
                        item
                        sm={12}
                        md={5}
                        sx={{
                            align: 'right'
                        }}
                    >

                        {/* <img
                        src={Doctor}
                        height='250'
                        width='230'
                        style={{
                            borderRadius: 15
                        }}
                    /> */}
                    </Grid>
                    <Grid
                        item
                        sm={12}
                        md={7}
                    >


                    </Grid>
                </Grid>

            </ScrollAnimation>
        </div>
    )
};

export default Home;
