import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import {
    Grid,
    IconButton,
    Typography,
    SvgIcon,
    useMediaQuery,
    Paper
} from '@mui/material';
import {
    makeStyles,
} from '@mui/styles';
import {
    useTheme
} from '@mui/material/styles';
import {
    LocationOn
} from '@mui/icons-material';
import Banner from '../banner';
import Logos from '../../assets/data/FooterLogos';
import Map from './Map';

const useStyles = makeStyles(
    theme => (
        {
            div: {
                height: 'auto',
                maxWidth: '100%',
                overflow: 'hidden',
                '& .leaflet-container': {
                    width: '100%',
                    height: '300px',
                }
            },
            paper1: {
                width: '100%',
                // padding: theme.spacing(1),
                color: 'white',
            },
            icon: {
                fontSize: '20px',
                color: '#000000',
                display: 'inline-flex',
                verticalAlign: 'middle',
                position: 'static',
                filter: 'opacity(0.8)',
                '&:hover, &:active': {
                    color: theme.palette.primary.dark,
                    fontWeight: '900',
                },
            },
            banner: {

            }
        }
    )
);

const Home = ({
    id
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up('sm'));

    return (
        <div
            className={classes.div}
            // align='center'
            id={id}
        >
            <ScrollAnimation
                animateIn="fadeIn"
                duration={3}
                animateOnce={true}
            >
                <Banner
                    title='Contact Us'
                    variant="h3"
                    sx={{
                        fontWeight: 900,
                        color: theme.palette.primary.dark,
                        marginBottom: 5
                    }}
                />
            </ScrollAnimation>

            <Grid
                container
                // spacing={2}
                direction='row'
                justifyContent="center"
                alignItems="center"
            >

                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                >
                    <ScrollAnimation
                        animateIn="fadeIn"
                        duration={3}
                        delay={800}
                        animateOnce={true}
                    >
                        <Paper
                            sx={{
                                padding: 2,
                                backgroundColor: '#2D5D62',
                                margin: 0,
                                minHeight: (matches && 180) || 200,
                                height: (matches && 180) || 200,
                                maxHeight: (matches && 180) || 200
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap'
                                }}
                                align='center'
                            >
                                {/* <Call
                                sx={{
                                    margin: 0.8,
                                    fontSize: 23,
                                    color: theme.palette.primary.dark
                                }}
                            /> */}
                                <Banner
                                    title={`Call Us At`}
                                    variant="h6"
                                    sx={{
                                        padding: 0,
                                        fontWeight: 900,
                                        color: theme.palette.primary.dark,
                                    }}
                                    align={(matches && 'center') || 'center'}
                                    noWrap
                                />
                            </div>

                            <Banner
                                title={`01711240969, 01746888725, 01307077590`}
                                variant="subtitle1"
                                sx={{
                                    padding: 0,
                                    marginLeft: 0.5,
                                    paddingLeft: matches && 1.5
                                }}
                                align={(matches && 'center') || 'center'}
                                gutterBottom
                            />
                            <div
                                style={{
                                    display: 'flex',
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap'
                                }}
                                align={(matches && 'center') || 'center'}
                            >
                                {/* <ConnectWithoutContact
                                sx={{
                                    margin: 0.8,
                                    fontSize: 23,
                                    color: theme.palette.primary.dark
                                }}
                            /> */}
                                <Banner
                                    title={`Connect With Us`}
                                    variant="h6"
                                    sx={{
                                        padding: 0,
                                        fontWeight: 900,
                                        color: theme.palette.primary.dark,
                                    }}
                                    align='center'
                                />
                            </div>
                            <Typography
                                color="inherit"
                                align='center'
                                className={classes.paper1}
                                sx={{
                                    paddingLeft: matches && 1.5
                                }}
                                gutterBottom
                            >
                                {
                                    Logos.map(
                                        (
                                            i,
                                            index
                                        ) => (
                                            <IconButton
                                                key={index}
                                                href={i.href}
                                            >
                                                <SvgIcon
                                                    className={classes.icon}
                                                >
                                                    <svg
                                                        xmlns={i.xmlns}
                                                        viewBox={i.viewbox}
                                                    >
                                                        <path
                                                            d={i.d}
                                                        />
                                                    </svg>
                                                </SvgIcon>
                                            </IconButton>
                                        )
                                    )
                                }
                            </Typography>
                        </Paper>
                    </ScrollAnimation>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                >
                    <ScrollAnimation
                        animateIn="fadeIn"
                        duration={3}
                        delay={1000}
                        animateOnce={true}
                    >
                        <Paper
                            sx={{
                                padding: 2,
                                backgroundColor: '#77A8A3',
                                minHeight: (matches && 180) || 200,
                                height: (matches && 180) || 200,
                                maxHeight: (matches && 180) || 200
                                // margin: 5
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    margin: '0px auto',
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    alignContent: 'center',
                                    alignItems: 'center'
                                }}
                                align={(matches && 'center') || 'center'}
                            >
                                <LocationOn
                                    sx={{
                                        fontSize: 30,
                                        color: '#880e4f',
                                    }}
                                />
                                <Banner
                                    title={`Skinfinity`}
                                    variant="h6"
                                    style={{
                                        padding: 0,
                                        fontWeight: 900,
                                        color: theme.palette.primary.dark,
                                    }}
                                    align={(matches && 'center') || 'center'}
                                    gutterBottom
                                />
                            </div>
                            <Banner
                                title={`3rd Floor, Fayeez Shatayu(Near YWCA School), Police Line Road, Barishal`}
                                variant="h6"
                                style={{
                                    padding: 0,
                                    paddingLeft: matches && 15
                                }}
                                align={(matches && 'center') || 'center'}
                                gutterBottom
                            />
                        </Paper>
                    </ScrollAnimation>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sm={12}
                    md={4}
                >
                    <ScrollAnimation
                        animateIn="fadeIn"
                        duration={3}
                        delay={1200}
                        animateOnce={true}
                    >
                        <Paper
                            sx={{
                                padding: 2,
                                //backgroundColor: '#b9ebff',
                                backgroundColor: '#B9D3CD',
                                minHeight: (matches && 180) || 200,
                                height: (matches && 180) || 200,
                                maxHeight: (matches && 180) || 200,
                                width: '100%'
                                // margin: 5
                            }}
                        >
                            <div
                                style={{
                                    display: 'flex',
                                    textAlign: 'center',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    alignContent: 'center',
                                    alignItems: 'center'
                                }}
                                align={(matches && 'center') || 'center'}
                            >
                                <LocationOn
                                    sx={{
                                        fontSize: 32,
                                        color: '#880e4f',
                                    }}
                                />
                                <Banner
                                    title={`Medinova Medical Services`}
                                    variant="h6"
                                    style={{
                                        padding: 0,
                                        fontWeight: 900,
                                        color: theme.palette.primary.dark,
                                    }}
                                    align={(matches && 'center') || 'center'}
                                // gutterBottom
                                />
                            </div>
                            <Banner
                                title={`Girzamoholla, Barishal`}
                                variant="h6"
                                style={{
                                    paddingLeft: matches && 15
                                }}
                                align={(matches && 'center') || 'center'}
                            // gutterBottom
                            />
                        </Paper>
                    </ScrollAnimation>
                </Grid>
                {/* <Grid
                    item
                    sm={12}
                    xs={12}
                    md={5}
                >
                </Grid> */}

                <Grid
                    item
                    xs={12}
                >
                    <ScrollAnimation
                        animateIn="fadeIn"
                        duration={3}
                        delay={1400}
                        animateOnce={true}
                    >
                        <Map
                            style={{
                                '& .leaflet-container': {
                                    width: '100%',
                                    height: '300px',
                                }
                            }}
                        />
                    </ScrollAnimation>
                </Grid>
            </Grid>
        </div >
    )
};

export default Home;
