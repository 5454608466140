import React from 'react';
import Appbar from '../appbarAlternate';
import Videos from './Videos';
import Footer from '../footer';
import Contact from '../contact';

const Layout = () => {
    document.title = "Skinfinity";
    // const FadeInLeft = styled.div`animation: 2s ${keyframes`${fadeInLeft}`} 1`;
    // const FadeInRight = styled.div`animation: 2s ${keyframes`${fadeInRight}`} 1`;

    return (
        <div style={{
            // overflowX: 'hidden',
            // backgroundColor: 'rgb(160,204,246)',
            // background: 'radial-gradient(circle, rgba(160,204,246,1) 0%, rgba(242,245,250,1) 100%)',
        }}>
            <Appbar />
            <Videos />
            <Contact />
            <Footer />
        </div>
    )
};

export default Layout;