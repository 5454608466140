import * as React from 'react';
import {
    Link,
} from 'react-router-dom';
import {
//    Paper,
    Grid,
    useTheme,
} from '@mui/material';
import ClampLines from 'react-clamp-lines';
import {
    makeStyles
} from '@mui/styles';
import Banner from '../banner';
import data from '../../assets/data/Blog.js';

const useStyles = makeStyles(
    theme => (
        {
            root: {
                // display: 'flex',
                margin: '70px auto',
                position: 'relative',
                width: '100%',
                height: 'auto',
                minHeight: '50vh',
                maxHeight: 'auto',
                overflowX: 'hidden',
                // background: theme.palette.secondary.dark,
                // [theme.breakpoints.down('lg')]: {
                //     marginTop: 3,
                //     height: '80vh'
                // },              // paddingBottom: 5,
                [theme.breakpoints.down('lg')]: {
                    marginTop: 60,
                    minHeight: '50vh',
                },
            },
            paper: {
                height: 'auto',
                width: '80%',
                // minHeight: '120px',
                // padding: theme.spacing(1),
                color: theme.palette.primary.dark,
                padding: theme.spacing(2),
                margin: theme.spacing(2),
                // '&:hover': {
                //     transform: 'scale(1.1)',
                //     boxShadow: '0px 5px 3px -3px #b3e5fc'
                // },
                backgroundColor: 'white',
                transition: 'transform 0.8s easeInOut',
                textAlign: 'left',
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    padding: theme.spacing(0.5)
                },
                // display: 'flex',
                // direction: 'column',
                // alignItems: 'flex-start'
                // MozTransition: 'transform2s ease-in-out',
                // OTransition: 'transform 2s ease-in-out',
                // transition: 'transform 2s ease-in-out'
            },
            control: {
                padding: theme.spacing(1),
            },
            icon: {
                // fontSize: '50px',
                margin: '5px auto',
                width: '100%',
                height: '200px',
                objectFit: 'cover'
            }
        }
    )
);

export default function BasicList({
    id
}) {
    const classes = useStyles();
    const theme = useTheme();

    console.log('dfghdfh', data)

    return (
        <div
            align='center'
            className={classes.root}
        >
            <Banner
                title='Blogs'
                variant="h3"
                flag='0'
                sx={{
                    color: theme.palette.primary.dark,
                    fontWeight: 900,
                    margin: '30px auto 20px'
                }}
            />
            <Grid
                container
                justifyContent='center'
                alignItems='flex-start'
                item
                xs={12}
                // spacing={2}
                sx={{
                    // margin: '0px auto'
                }}
            >
                {
                    data.map(
                        (
                            i, index
                        ) => {
                            // var Icon = i.Icon;
                            return (
                                <Grid
                                    component={Link}
                                    to={`/blogs/${i.slug}`}
                                    key={index}
                                    item
                                    xs={12}
                                    sm={12}
                                    md={3}
                                    lg={3}
                                    container
                                    justifyContent='center'
                                    alignItems='center'
                                    // xl={2}
                                    className={classes.paper}
                                    sx={{
                                        backgroundColor: theme.palette.secondary.contrastText,
                                        // margin: '10px auto',
                                        color: theme.palette.primary.dark,
                                        borderRadius: 2,
                                        margin: theme.spacing(5 * 0.5),
                                        height: '520px',
                                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                        // margin: '10px auto',
                                        '& .clamp-lines': {
                                            color: theme.palette.secondary.dark
                                        },
                                        '& .clamp-lines__button': {
                                            display: 'none'
                                        },
                                        [theme.breakpoints.down('sm')]: {
                                            height: '550px',
                                            margin: theme.spacing(3 * 0.5),
                                            padding: theme.spacing(1)
                                        },
                                        textDecoration: 'none'
                                    }}
                                >
                                    <Grid
                                        item
                                        md={12}
                                        align='center'
                                    >
                                        <Banner
                                            title={i.title}
                                            variant='h6'
                                            align='left'
                                            sx={{
                                                fontWeight: 900,
                                                padding: 0
                                            }}
                                        />
                                        {/* </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        > */}
                                        <Banner
                                            title={i.published_date}
                                            variant='subtitle1'
                                            align='left'
                                            sx={{
                                                padding: 0,
                                                color: '#607d8b'
                                            }}
                                            gutterBottom
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        md={12}
                                        align='center'
                                    >
                                        <img alt={i.title} src={i.images[0]} className={classes.icon} />
                                    </Grid>
                                    {/* <Grid
                                        item
                                        container
                                        direction='column'
                                        md={12}
                                        alignItems='flex-start'
                                        justifyContent='flex-start'
                                        sx={{
                                            padding: 0,
                                            '& .clamp-lines': {
                                                color: theme.palette.secondary.dark
                                            },
                                            '& .clamp-lines__button': {
                                                display: 'none'
                                            }
                                        }}
                                    > */}
                                    <Grid
                                        item
                                        xs={12}
                                    >

                                        {/* </Grid>
                                        <Grid
                                            item
                                            xs={12}
                                        > */}
                                        <ClampLines
                                            // text={text_to_clamp}
                                            text={i.intro}
                                            id="really-unique-id"
                                            lines={4}
                                            ellipsis="..."
                                            moreText={false}
                                            lessText={false}
                                            style={{
                                                // sx={{
                                                padding: 0,
                                                marginTop: 1,
                                                color: theme.palette.secondary.dark
                                                // }}
                                            }}
                                        />
                                        {/* <Banner
                                                variant='body1'
                                                align='left'
                                                sx={{
                                                    padding: 0,
                                                    marginTop: 1,
                                                    color: theme.palette.secondary.dark
                                                }}
                                            /> */}
                                        {/* </Grid> */}

                                    </Grid>

                                </Grid>
                            )
                        }
                    )
                }
            </Grid>
        </div >
    );
};