import * as React from 'react';
import {
    useParams
} from "react-router-dom";
import {
    FacebookShareButton,
    FacebookIcon,
    FacebookMessengerIcon,
    FacebookMessengerShareButton,
    LinkedinIcon,
    LinkedinShareButton,
    TwitterIcon,
    TwitterShareButton,
    ViberIcon,
    ViberShareButton,
    WhatsappIcon,
    WhatsappShareButton,
} from "react-share";
import {
    useTheme
} from '@mui/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Typography from '@mui/material/Typography';
import data from '../../assets/data/Videos.js';
import Appbars from '../appbarAlternate/index.jsx';
import Footer from '../footer';
import Contact from '../contact';

export default function RecipeReviewCard() {
    // const [expanded, setExpanded] = React.useState(false);

    // const handleExpandClick = () => {
    //     setExpanded(!expanded);
    // };
    var {
        slug
    } = useParams();
    const theme = useTheme();

    let obj = data.find(o => o.slug === slug);

    return (
        <>
            <Appbars />
            <Card sx={{
                width: '60%',
                backgroundColor: theme.palette.secondary.contrastText,
                margin: '90px auto',
                [theme.breakpoints.down('sm')]: {
                    width: '95%',
                    margin: '70px auto'
                },
                '& .MuiCardHeader-content': {
                    color: theme.palette.primary.dark
                }
            }}>
                <CardHeader
                    // avatar={
                    //     <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                    //         R
                    //     </Avatar>
                    // }
                    // action={
                    //     <IconButton aria-label="settings">
                    //         <MoreVertIcon />
                    //     </IconButton>
                    // }
                    title={obj.title}
                    subheader={obj.published_date}
                />
                <CardActions disableSpacing
                    sx={{
                        paddingLeft: 2,
                        color: theme.palette.primary.dark,
                        fontWeight: 900
                    }}
                >
                    Share In
                    <FacebookShareButton
                        url={`https://skinfinity.info/videos/${obj.slug}`}
                        quote={obj.title}
                        description={obj.title}
                    // className="Demo__some-network__share-button"
                    >
                        <FacebookIcon
                            size={32}
                            round
                            style={{
                                verticalAlign: 'middle',
                                paddingLeft: 3,
                                paddingRight: 3,
                                paddingBottom: 4
                            }}
                        />
                    </FacebookShareButton>
                    <FacebookMessengerShareButton
                        title={obj.title}
                        url={`https://skinfinity.info/videos/${obj.slug}`}
                        appId={682933902690427}
                    >
                        <FacebookMessengerIcon
                            size={32}
                            round
                            style={{
                                verticalAlign: 'middle',
                                paddingLeft: 3,
                                paddingRight: 3,
                                paddingBottom: 4
                            }} />
                    </FacebookMessengerShareButton>
                    <TwitterShareButton
                        title={obj.title}
                        url={`https://skinfinity.info/videos/${obj.slug}`}
                    >
                        <TwitterIcon
                            size={32}
                            round
                            style={{
                                verticalAlign: 'middle',
                                paddingLeft: 3,
                                paddingRight: 3,
                                paddingBottom: 4
                            }} />
                    </TwitterShareButton>
                    <WhatsappShareButton
                        title={obj.title}
                        url={`https://skinfinity.info/videos/${obj.slug}`}
                    >
                        <WhatsappIcon
                            size={32}
                            round
                            style={{
                                verticalAlign: 'middle',
                                paddingLeft: 3,
                                paddingRight: 3,
                                paddingBottom: 4
                            }} />
                    </WhatsappShareButton>
                    <ViberShareButton
                        title={obj.title}
                        url={`https://skinfinity.info/videos/${obj.slug}`}
                    >
                        <ViberIcon
                            size={32}
                            round
                            style={{
                                verticalAlign: 'middle',
                                paddingLeft: 3,
                                paddingRight: 3,
                                paddingBottom: 4
                            }}
                        />
                    </ViberShareButton>
                    <LinkedinShareButton
                        title={obj.title}
                        url={`https://skinfinity.info/videos/${obj.slug}`}
                    >
                        <LinkedinIcon
                            size={32}
                            round
                            style={{
                                verticalAlign: 'middle',
                                paddingLeft: 3,
                                paddingRight: 3,
                                paddingBottom: 4
                            }} />
                    </LinkedinShareButton>
                </CardActions>
                {/* <CardMedia
                    component="img"
                    height="250"
                    sx={{
                        objectFit: 'contain'
                    }}
                    image={obj.images[0]}
                    alt={obj.title}
                /> */}
                <CardContent
                    sx={{
                        // paddingBottom: 0
                    }}
                >
                    <div
                        style={{
                            width: '100%',
                            height: '60vh',
                            margin: '20px auto',
                            [theme.breakpoints.down('sm')]: {
                                height: '60vh',
                            },
                        }}
                    >
                        <iframe
                            style={{
                                height: '100%',
                                width: '100%',
                                margin: '0 auto'
                            }}
                            title={obj.title}
                            src={`https://www.youtube.com/embed/${obj.youtube_id}`}
                        >
                        </iframe>
                    </div>
                    <Typography variant='body1'
                        gutterBottom
                    // color="text.secondary"
                    >
                        {obj.description}
                    </Typography>
                    {/* </CardContent> */}

                    {/* <Collapse in={expanded} timeout="auto" unmountOnExit> */}
                    {/* <CardContent> */}
                </CardContent>
                {/* </Collapse> */}
            </Card>
            <Contact />
            <Footer />
        </>

    );
}
