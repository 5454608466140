const options = [
    {
        menu: 'Home',
        path: 'home',
    },
    {
        menu: 'Doctor',
        path: 'doctor',
    },
    {
        menu: 'Services',
        path: 'Services',
    },
    {
        menu: 'Reviews',
        path: 'reviews',
    },
    // {
    //     menu: 'Appointment',
    //     path: 'appointment',
    // },
    {
        menu: 'Contact Us',
        path: 'contact',
    },
];

export const links = [
    {
        menu: 'Home',
        path: '/',
    },
    {
        menu: 'Reviews',
        path: '/reviews',
    },
    {
        menu: 'Blog',
        path: '/blogs',
    },
    {
        menu: 'Videos',
        path: '/videos',
    },
];

export default options;