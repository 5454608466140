import React from 'react';
import {
    NavLink,
    Link
} from 'react-router-dom';
// import {
//     Link,
//     animateScroll as scroll,
// } from "react-scroll";
import {
    AppBar,
    Toolbar,
    Button,
    IconButton,
} from '@mui/material';
import {
    makeStyles
} from '@mui/styles';
import Logo from '../../assets/logo.png';

import {
    links
} from '../../assets/data/AppMenus';

const useStyles = makeStyles(
    theme => (
        {
            root: {
                flexGrow: 1,
                '& .MuiButtonBase-root': {
                    padding: 0,
                    margin: 5,
                    color: 'black'
                },

                // padding: theme.spacing(1),
            },
            button: {
                margin: theme.spacing(1),
                fontWeight: 800,
                textTransform: 'capitalize',
                color: '#3e2723'
            },
            appbar: {
                background: 'transparent',
                backgroundColor: theme.palette.secondary.contrastText,
                // backdropFilter: 'blur(3px)',
                boxShadow: 'none',
                width: '100%',
                opacity: '0.96',
                '& .MuiButton-textPrimary': {
                    color: theme.palette.primary.dark,
                    fontWeight: 800
                }
            }
        }
    )
);

const AppbarForBigger = () => {
    // const scrollToTop = () => {
    //     scroll.scrollToTop();
    // };

    const classes = useStyles();

    return (
        <AppBar
            position="fixed"
            className={classes.appbar}
        >
            <Toolbar
                
            >
                <div
                    className={classes.root}
                >
                    <IconButton
                        component={Link}
                        to='/'
                    // onClick={scrollToTop}
                    >
                        <img
                            src={Logo}
                            alt="Skinfinity"
                            height='60'
                            width='150'
                        />
                    </IconButton>
                </div>
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    {/* {
                        Options.map(
                            option => (
                                <Link
                                    key={option.path}
                                    activeClass="active"
                                    to={option.path}
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={1000}
                                >
                                    <Button
                                        size="medium"
                                        color='primary'
                                        variant='text'
                                        className={classes.button}
                                        onClick={option.path === 'home' ? scrollToTop : undefined}
                                    >
                                        {option.menu}
                                    </Button>
                                </Link>
                            )
                        )
                    } */}
                    {
                        links.map(
                            option => (
                                <NavLink
                                    key={option.path}
                                    activeClass="active"
                                    to={option.path}
                                    style={{
                                        textDecoration: 'none'
                                    }}
                                    className={(navData) => (navData.isActive ? 'active' : '')}
                                    activestyle={{
                                        backgroundColor: 'black'
                                        // backgroundColor: fade('white', 0.15),
                                        // color: "red"
                                    }}
                                >
                                    <Button
                                        size="medium"
                                        color='primary'
                                        variant='text'
                                        className={classes.button}
                                    // onClick={option.path === 'home' ? scrollToTop : undefined}
                                    >
                                        {option.menu}
                                    </Button>
                                </NavLink>
                            )
                        )
                    }
                </div>

            </Toolbar>
        </AppBar>
    );
};

export default AppbarForBigger;
