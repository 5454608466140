import React, {
    useState,
    useEffect
} from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import {
    Paper,
    Grid,
    Typography,
//    Button,
    useTheme,
    ImageList,
    ImageListItem
} from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    makeStyles
} from '@mui/styles';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import {
    FormatQuote
} from '@mui/icons-material';
import Banner from '../banner';
import data from '../../assets/data/Reviews';

const useStyles = makeStyles(
    theme => (
        {
            root: {
                // display: 'flex',
                marginTop: 60,
                position: 'relative',
                width: '100%',
                height: 'auto',
                minHeight: '50vh',
                maxHeight: 'auto',
                overflowX: 'hidden',
                // background: theme.palette.secondary.dark,
                // [theme.breakpoints.down('lg')]: {
                //     marginTop: 3,
                //     height: '80vh'
                // },              // paddingBottom: 5,
                [theme.breakpoints.down('lg')]: {
                    marginTop: 60,
                    minHeight: '50vh',
                },
            },
            paper: {
                // height: 'auto',
                // width: 550,
                // minHeight: '360px',
                // padding: theme.spacing(1),
                color: theme.palette.primary.dark,
                backgroundColor: '#bbdefb',
                [theme.breakpoints.down('sm')]: {
                    width: '90%',
                },
                // MozTransition: 'transform2s ease-in-out',
                // OTransition: 'transform 2s ease-in-out',
                // transition: 'transform 2s ease-in-out'
            },
            control: {
                padding: theme.spacing(1),
            },
            icon: {
                // fontSize: '50px',
                margin: '5px auto',
                width: '50px',
                height: '50px'
            },
            rotate: {
                transform: 'rotate(180deg)',
                color: theme.palette.primary.dark,
                opacity: 0.5,
            },
            notrotate: {
                transform: 'rotate(360deg)',
                color: theme.palette.primary.dark,
                opacity: 0.5,
                fontSize: 40
            },
            button: {
                '& .MuiButtonBase-root': {
                    color: theme.palette.primary.dark,
                }
            }
        }
    )
);

function srcset(image, size, rows = 1, cols = 1) {
    return {
        src: `${image}?w=${size * cols}&h=${size * rows}&fit=crop&auto=format`,
        srcSet: `${image}?w=${size * cols}&h=${size * rows
            }&fit=crop&auto=format&dpr=2 2x`
    };
}

export default function BasicList({
    id
}) {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const breakpoints = {
        xs: 0,
        sm: 600,
        md: 960,
        lg: 1280,
        xl: 1920
    }

    const getColumns = (width) => {
        if (width < breakpoints.sm) {
            return 1
        } else if (width < breakpoints.md) {
            return 1
        } else if (width < breakpoints.lg) {
            return 2
        } else if (width < breakpoints.xl) {
            return 2
        } else {
            return 2
        }
    }

    const [columns, setColumns] = useState(getColumns(window.innerWidth))
    const updateDimensions = () => {
        setColumns(getColumns(window.innerWidth))
    }

    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
        return () => window.removeEventListener("resize", updateDimensions);
    }, []);

    const r = data.length;
    const e = data.slice(0, r - 1);

    return (
        <div
            align='center'
            id={id}
        >
            <ScrollAnimation
                animateIn="fadeIn"
                duration={3}
                animateOnce={true}
            >
                <Banner
                    title='Reviews'
                    variant="h3"
                    flag='0'
                    sx={{
                        color: theme.palette.primary.dark,
                        fontWeight: 900,
                        // marginBottom: 10
                    }}
                />
            </ScrollAnimation>
            <ScrollAnimation
                animateIn="fadeIn"
                duration={3}
                delay={800}
                animateOnce={true}
            >
                <Grid
                    container
                    justifyContent='flex-start'
                    alignItems="flex-start"
                    item
                    xs={12}
                // spacing={2}
                // sx={{
                //     margin: '0px'
                // }}
                >
                    <ImageList
                        sx={{ width: '100%', height: 'auto' }}
                        variant="quilted"
                        cols={columns}
                        rowHeight={100}
                    >
                        {
                            e.map(
                                (
                                    i, index
                                ) => (
                                    <ImageListItem
                                        key={index}
                                        cols={i.cols || 1}
                                        rows={i.rows || 1}
                                    >
                                        <Grid
                                            key={index}
                                            item
                                            xs={12}
                                            // sm={12}
                                            // md={12}
                                            // lg={6}
                                            {...srcset(i.review, 121, i.rows, i.cols)}
                                        // xl={2}
                                        // sx={{
                                        //     margin: '0px'
                                        // }}
                                        >
                                            <Paper
                                                elevation={index === 0 ? 0 : 1}
                                                className={classes.paper}
                                                sx={{
                                                    backgroundColor: i.backgroundColor,
                                                    height: i.height,
                                                    width: i.width,
                                                    margin: '10px 0px',
                                                    // color: '#003B73',
                                                    padding: index === 0 ? 2 : 8,
                                                    paddingLeft: index === 0 ? 13 : 8,
                                                    color: index === 0 ? theme.palette.primary.dark : 'black',
                                                    [theme.breakpoints.down('md')]: {
                                                        padding: 1,
                                                        width: '95%',
                                                        marginLeft: (index === 2 || index === 4) ? '3%' : '',
                                                        marginRight: (index === 1 || index === 3) ? '3%' : ''
                                                    },
                                                    [theme.breakpoints.up('md')]: {
                                                        width: '90%',
                                                        paddingLeft: index === 0 ? 3 : 8,
                                                        marginLeft: (index === 2 || index === 4) ? '5%' : '',
                                                        marginRight: (index === 1 || index === 3) ? '5%' : ''
                                                    },
                                                }}
                                            >
                                                <Banner
                                                    title={i.review}
                                                    variant={
                                                        (
                                                            index === 0
                                                            &&
                                                            matches
                                                            &&
                                                            'h4'
                                                        )
                                                        ||
                                                        (
                                                            index === 0
                                                            &&
                                                            !matches
                                                            &&
                                                            'h3'
                                                        )
                                                        ||
                                                        'h6'
                                                    }
                                                    flag='0'
                                                    align='left'
                                                />
                                                {
                                                    index !== 0
                                                    &&
                                                    <Grid
                                                        container
                                                        direction="row"
                                                        justifyContent="center"
                                                        alignItems="center"
                                                        spacing={!matches && 1}
                                                    >
                                                        <Grid
                                                            item
                                                            sm={3}
                                                        >
                                                            <AccountCircleIcon sx={{ fontSize: 80 }} className={classes.icon} />
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            sm={5}
                                                        >
                                                            <Typography
                                                                align='left'
                                                            >
                                                                {i.name}
                                                                <br />
                                                                Client
                                                            </Typography>
                                                        </Grid>
                                                        <Grid
                                                            item
                                                            sm={3}
                                                        >
                                                            <FormatQuote
                                                                sx={{
                                                                    fontSize: 120
                                                                }}
                                                                className={classes.rotate} />
                                                        </Grid>
                                                    </Grid>
                                                }

                                            </Paper>
                                        </Grid>
                                    </ImageListItem>
                                )
                            )
                        }
                    </ImageList>
                    {/* <Grid
                    item
                    xs={12}
                >

                </Grid> */}
                </Grid>
            </ScrollAnimation>
        </div>
    );
};
