import React, {
    useState
} from 'react';
import {
    NavLink
} from 'react-router-dom';
import {
    Link,
    animateScroll as scroll
} from "react-scroll";
import {
    MenuItem,
    AppBar,
    Toolbar,
    IconButton,
    Button,
    Drawer,
} from '@mui/material';
import {
    makeStyles,
    useTheme
} from '@mui/styles';
import {
    Close,
    Menu,
} from '@mui/icons-material';
import Logo from '../../assets/logo.png';
import Options, {
    links
} from '../../assets/data/AppMenus';

const useStyles = makeStyles(
    theme => (
        {
            root: {
                flexGrow: 1,
                '& .MuiButtonBase-root': {
                    padding: 0
                }
            },
            appbar: {
                background: 'transparent',
                boxShadow: 'none',
                backgroundColor: theme.palette.secondary.dark,
                backdropFilter: 'blur(3px)',
                overflow: 'hidden',
            },
            button: {
                // margin: theme.spacing.unit,
                fontWeight: 900,
                padding: 0,
            },
            menu: {
                color: theme.palette.primary.dark,
                padding: theme.spacing(2),
                minWidth: '200px',
                width: 'auto',
            },
            clear: {
                height: '10px',
                width: '100px',
                margin: '0 auto',
                color: theme.palette.primary.dark,
            },
        }
    )
);

const AppbarSmaller = () => {
    const [
        anchorEl,
        setAnchorEl
    ] = useState(null);

    const theme = useTheme();

    const scrollToTop = () => {
        scroll.scrollToTop();
    };

    const handleClick = event => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = event => {
        setAnchorEl(null);
    };

    const classes = useStyles();
    const open = Boolean(anchorEl);

    return (
        <AppBar
            position="fixed"
            className={classes.appbar}
        >
            <Toolbar
                //className={classes.appbar}
            >
                <div
                    //className={classes.root}
                    onClick={scrollToTop}
                >
                    <IconButton
                        className={classes.button}
                    >
                        <img src={Logo}
                            alt="Skinfinity"
                            height='40'
                            width='150'
                        />
                    </IconButton>


                </div>
                <IconButton
                    className={classes.button}
                    aria-owns={open ? 'fade-menu' : null}
                    aria-haspopup="true"
                    color='primary'
                    onClick={handleClick}
                >
                    <Menu />
                </IconButton>
                <Drawer
                    id="fade-menu"
                    className={classes.menu}
                    anchorel={anchorEl}
                    open={open}
                    anchor='right'
                    transitionDuration={500}
                    onClose={handleClose}
                // TransitionComponent={Fade}
                >
                    {
                        Options.map(
                            option => (
                                <Link
                                    key={option.path}
                                    activeClass="active"
                                    to={option.path}
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={1000}
                                    style={{
                                        textDecoration: 'none',
                                        color: theme.palette.primary.dark
                                    }}
                                >
                                    <MenuItem
                                        className={classes.menu}
                                        onClick={handleClose}
                                    >
                                        {option.menu}
                                    </MenuItem>
                                </Link>
                            )
                        )
                    }
                    {
                        links.slice(2).map(
                            option => (
                                <NavLink
                                    key={option.path}
                                    activeClass="active"
                                    to={option.path}
                                    style={{
                                        textDecoration: 'none',
                                        color: theme.palette.primary.dark
                                    }}
                                >
                                    <MenuItem
                                        className={classes.menu}
                                        onClick={handleClose}
                                    >
                                        {option.menu}
                                    </MenuItem>
                                </NavLink>
                            )
                        )
                    }
                    <div
                        align='center'
                    >
                        <Button
                            className={classes.clear}
                            // color='secondary'
                            onClick={handleClose}
                            startIcon={
                                <Close
                                    sx={{
                                        color: theme.palette.primary.dark
                                    }}
                                />
                            }
                            sx={{
                                color: theme.palette.primary.dark
                            }}
                        >
                            Close
                        </Button>
                    </div>
                </Drawer>
            </Toolbar>
        </AppBar>
    )
};

export default AppbarSmaller;
