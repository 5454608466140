import Pioneer from '../home/Pioneer.svg';
import Experienced from '../home/Experienced.svg';
import WorkWithRigor from '../home/WorkWithRigor.svg';
// import Pioneer from './home/Pioneer.svg';

const data = [
    {
        title: 'A pioneer in Laser Skin Care in Barisal',
        icon: Pioneer
    },
    {
        title: 'We work with rigor',
        icon: Experienced
    },
    {
        title: 'Skilled & experienced doctor',
        icon: WorkWithRigor
    },
    {
        title: '7 years of dermotological experience',
        icon: Pioneer
    },
]

export default data;