import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
    useTheme
} from '@mui/material/styles';
import AppbarForBigger from './AppbarForBigger';
import AppbarForSmaller from './AppbarForSmaller';

function useWidth() {
    const theme = useTheme();
    const keys = [...theme.breakpoints.keys].reverse();

    return (
        keys.reduce(
            (
                output, key
            ) => {
                // eslint-disable-next-line react-hooks/rules-of-hooks
                const matches = useMediaQuery(theme.breakpoints.up(key));
                return !output && matches ? key : output;
            },
            null
        )
        ||
        'xs'
    );
}

const Appbars = () => {
    const width = useWidth();

    if (
        width === 'xs'
        ||
        width === 'sm'
    ) {
        return <AppbarForSmaller />
    }
    return <AppbarForBigger />;
}

export default Appbars;