const blogs = [
    {
        "id": "62ad9102421aa9d457e509dc",
        "created_at": "0001-01-01T00:00:00Z",
        "updated_at": "2022-06-18T08:52:50.153Z",
        "slug": "first-blog1",
        "deleted": false,
        "deleted_at": "0001-01-01T00:00:00Z",
        "title": "Laoreet id donec ultrices tincidunt. Quisque sagittis purus sit amet volutpat consequat mauris",
        "intro": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "content": `
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Urna et pharetra pharetra massa massa ultricies mi quis. Bibendum at varius vel pharetra vel turpis nunc eget. Urna cursus eget nunc scelerisque viverra. Dignissim convallis aenean et tortor at risus viverra adipiscing. Vel turpis nunc eget lorem dolor sed viverra ipsum nunc. Odio tempor orci dapibus ultrices in iaculis. Adipiscing vitae proin sagittis nisl rhoncus mattis rhoncus urna neque. Dictum non consectetur a erat nam at. Arcu ac tortor dignissim convallis aenean et tortor at risus. Mauris ultrices eros in cursus turpis massa tincidunt dui ut. Scelerisque purus semper eget duis at. Aliquam ultrices sagittis orci a scelerisque purus semper eget duis. Id diam maecenas ultricies mi eget mauris pharetra et. In tellus integer feugiat scelerisque varius morbi enim nunc faucibus. Sit amet dictum sit amet justo donec enim. Amet consectetur adipiscing elit pellentesque habitant morbi tristique senectus et. Elementum facilisis leo vel fringilla est ullamcorper eget. Orci nulla pellentesque dignissim enim.

        Sem et tortor consequat id porta nibh venenatis cras. Enim facilisis gravida neque convallis a. Tellus id interdum velit laoreet id donec. Malesuada pellentesque elit eget gravida cum sociis natoque. Malesuada fames ac turpis egestas sed tempus. Odio tempor orci dapibus ultrices in. Dolor purus non enim praesent elementum facilisis leo vel fringilla. Sit amet tellus cras adipiscing. Hac habitasse platea dictumst vestibulum rhoncus est. Vitae turpis massa sed elementum tempus egestas. Rutrum tellus pellentesque eu tincidunt. Viverra nam libero justo laoreet sit amet.

        Et netus et malesuada fames ac turpis egestas. Pharetra convallis posuere morbi leo urna molestie at. Ut consequat semper viverra nam libero justo. Sed elementum tempus egestas sed sed risus pretium quam vulputate. Orci a scelerisque purus semper eget. Eu nisl nunc mi ipsum faucibus. Est sit amet facilisis magna. A cras semper auctor neque vitae tempus. Adipiscing diam donec adipiscing tristique risus nec feugiat in. In cursus turpis massa tincidunt dui. Diam vulputate ut pharetra sit amet aliquam. Nulla porttitor massa id neque aliquam vestibulum morbi blandit. Amet nulla facilisi morbi tempus iaculis urna id volutpat. Condimentum lacinia quis vel eros donec ac odio tempor. Arcu felis bibendum ut tristique et egestas quis ipsum suspendisse.
        `,
        "writer": "Dr. Md. Rezwan Kaiser",
        "photo_credit": "Who Knows",
        "images": [
            "https://i.ibb.co/PrMRSQn/Jonty-rhodes02.jpg"
        ],
        "published_date": "June 18, 2022",
        "blog_slug": "bangladesh-dropping-catches-at-will",
        "series_id": "",
        "match_id": "west-indies-vs-bangladesh-1st-test-2022-16-06-2022",
        "photo_caption": "Laoreet id donec ultrices tincidunt. Quisque sagittis purus sit amet volutpat consequat mauris",
        "photo_attribute_link": "",
        "sequence": 58
    },
    {
        "id": "62ad9102421aa9d457e509dc",
        "created_at": "0001-01-01T00:00:00Z",
        "updated_at": "2022-06-18T08:52:50.153Z",
        "deleted": false,
        "slug": "first-blog2",
        "deleted_at": "0001-01-01T00:00:00Z",
        "title": "Laoreet id donec ultrices tincidunt. Quisque sagittis purus sit amet volutpat consequat mauris",
        "intro": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "content": `
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Urna et pharetra pharetra massa massa ultricies mi quis. Bibendum at varius vel pharetra vel turpis nunc eget. Urna cursus eget nunc scelerisque viverra. Dignissim convallis aenean et tortor at risus viverra adipiscing. Vel turpis nunc eget lorem dolor sed viverra ipsum nunc. Odio tempor orci dapibus ultrices in iaculis. Adipiscing vitae proin sagittis nisl rhoncus mattis rhoncus urna neque. Dictum non consectetur a erat nam at. Arcu ac tortor dignissim convallis aenean et tortor at risus. Mauris ultrices eros in cursus turpis massa tincidunt dui ut. Scelerisque purus semper eget duis at. Aliquam ultrices sagittis orci a scelerisque purus semper eget duis. Id diam maecenas ultricies mi eget mauris pharetra et. In tellus integer feugiat scelerisque varius morbi enim nunc faucibus. Sit amet dictum sit amet justo donec enim. Amet consectetur adipiscing elit pellentesque habitant morbi tristique senectus et. Elementum facilisis leo vel fringilla est ullamcorper eget. Orci nulla pellentesque dignissim enim.

        Sem et tortor consequat id porta nibh venenatis cras. Enim facilisis gravida neque convallis a. Tellus id interdum velit laoreet id donec. Malesuada pellentesque elit eget gravida cum sociis natoque. Malesuada fames ac turpis egestas sed tempus. Odio tempor orci dapibus ultrices in. Dolor purus non enim praesent elementum facilisis leo vel fringilla. Sit amet tellus cras adipiscing. Hac habitasse platea dictumst vestibulum rhoncus est. Vitae turpis massa sed elementum tempus egestas. Rutrum tellus pellentesque eu tincidunt. Viverra nam libero justo laoreet sit amet.

        Et netus et malesuada fames ac turpis egestas. Pharetra convallis posuere morbi leo urna molestie at. Ut consequat semper viverra nam libero justo. Sed elementum tempus egestas sed sed risus pretium quam vulputate. Orci a scelerisque purus semper eget. Eu nisl nunc mi ipsum faucibus. Est sit amet facilisis magna. A cras semper auctor neque vitae tempus. Adipiscing diam donec adipiscing tristique risus nec feugiat in. In cursus turpis massa tincidunt dui. Diam vulputate ut pharetra sit amet aliquam. Nulla porttitor massa id neque aliquam vestibulum morbi blandit. Amet nulla facilisi morbi tempus iaculis urna id volutpat. Condimentum lacinia quis vel eros donec ac odio tempor. Arcu felis bibendum ut tristique et egestas quis ipsum suspendisse.
        `,
        "writer": "Dr. Md. Rezwan Kaiser",
        "photo_credit": "Who Knows",
        "images": [
            "https://i.ibb.co/PrMRSQn/Jonty-rhodes02.jpg"
        ],
        "published_date": "June 18, 2022",
        "blog_slug": "bangladesh-dropping-catches-at-will",
        "series_id": "",
        "match_id": "west-indies-vs-bangladesh-1st-test-2022-16-06-2022",
        "photo_caption": "Laoreet id donec ultrices tincidunt. Quisque sagittis purus sit amet volutpat consequat mauris",
        "photo_attribute_link": "",
        "sequence": 58
    },
    {
        "id": "62ad9102421aa9d457e509dc",
        "created_at": "0001-01-01T00:00:00Z",
        "updated_at": "2022-06-18T08:52:50.153Z",
        "deleted": false,
        "slug": "first-blog3",
        "deleted_at": "0001-01-01T00:00:00Z",
        "title": "Laoreet id donec ultrices tincidunt. Quisque sagittis purus sit amet volutpat consequat mauris",
        "intro": "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        "content": `
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Urna et pharetra pharetra massa massa ultricies mi quis. Bibendum at varius vel pharetra vel turpis nunc eget. Urna cursus eget nunc scelerisque viverra. Dignissim convallis aenean et tortor at risus viverra adipiscing. Vel turpis nunc eget lorem dolor sed viverra ipsum nunc. Odio tempor orci dapibus ultrices in iaculis. Adipiscing vitae proin sagittis nisl rhoncus mattis rhoncus urna neque. Dictum non consectetur a erat nam at. Arcu ac tortor dignissim convallis aenean et tortor at risus. Mauris ultrices eros in cursus turpis massa tincidunt dui ut. Scelerisque purus semper eget duis at. Aliquam ultrices sagittis orci a scelerisque purus semper eget duis. Id diam maecenas ultricies mi eget mauris pharetra et. In tellus integer feugiat scelerisque varius morbi enim nunc faucibus. Sit amet dictum sit amet justo donec enim. Amet consectetur adipiscing elit pellentesque habitant morbi tristique senectus et. Elementum facilisis leo vel fringilla est ullamcorper eget. Orci nulla pellentesque dignissim enim.

        Sem et tortor consequat id porta nibh venenatis cras. Enim facilisis gravida neque convallis a. Tellus id interdum velit laoreet id donec. Malesuada pellentesque elit eget gravida cum sociis natoque. Malesuada fames ac turpis egestas sed tempus. Odio tempor orci dapibus ultrices in. Dolor purus non enim praesent elementum facilisis leo vel fringilla. Sit amet tellus cras adipiscing. Hac habitasse platea dictumst vestibulum rhoncus est. Vitae turpis massa sed elementum tempus egestas. Rutrum tellus pellentesque eu tincidunt. Viverra nam libero justo laoreet sit amet.

        Et netus et malesuada fames ac turpis egestas. Pharetra convallis posuere morbi leo urna molestie at. Ut consequat semper viverra nam libero justo. Sed elementum tempus egestas sed sed risus pretium quam vulputate. Orci a scelerisque purus semper eget. Eu nisl nunc mi ipsum faucibus. Est sit amet facilisis magna. A cras semper auctor neque vitae tempus. Adipiscing diam donec adipiscing tristique risus nec feugiat in. In cursus turpis massa tincidunt dui. Diam vulputate ut pharetra sit amet aliquam. Nulla porttitor massa id neque aliquam vestibulum morbi blandit. Amet nulla facilisi morbi tempus iaculis urna id volutpat. Condimentum lacinia quis vel eros donec ac odio tempor. Arcu felis bibendum ut tristique et egestas quis ipsum suspendisse.
        `,
        "writer": "Dr. Md. Rezwan Kaiser",
        "photo_credit": "Who Knows",
        "images": [
            "https://i.ibb.co/PrMRSQn/Jonty-rhodes02.jpg"
        ],
        "published_date": "June 18, 2022",
        "blog_slug": "bangladesh-dropping-catches-at-will",
        "series_id": "",
        "match_id": "west-indies-vs-bangladesh-1st-test-2022-16-06-2022",
        "photo_caption": "Laoreet id donec ultrices tincidunt. Quisque sagittis purus sit amet volutpat consequat mauris",
        "photo_attribute_link": "",
        "sequence": 58
    },
];

export default blogs;