import Pic from '../Pictures/index';
import * as React from 'react';
//import { experimentalStyled as styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
//import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
//import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
//import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
//import CardActionArea from '@material-ui/core/CardActionArea';
//import CardActions from '@material-ui/core/CardActions';
import Card from '@material-ui/core/Card';
//import Pic from './Pictures/index.js';
import { makeStyles } from '@material-ui/core/styles';
import { CssBaseline } from '@mui/material';
//import Link from '@mui/material/Link';

/*
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1D2027' : '#afeh',
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  color:'pink',
  
\
}));
*/

const useStyles = makeStyles({
  root: {
    maxWidth: 345,
  },
  media: {
    height: 140,
  },
  CC1:{
    backgroundColor:'gray',
  },
  img:{
    
    backgroundColor: '#1DE9B6',
    //padding:'1rem',
    //margin:'1rem',
    //me height:'50vh',
    //width:'100vw',
    objectFit:'inherit',
    //height:'430px',
    //width:'430px',
    //maxHeight:'400px',
    height:'100%',
    minHeight:'400px',
    width:"100%",
    marginInline:'auto',
    backgroundSize: 'cover', backgroundRepeat: 'no-repeat',backgroundPosition: 'center',
    boxSizing:'inherit',
    //margin:'5px auto',
    //objectPosition: '50% 50%',
    //aspectRatio:'inherit',
    
    
    
    
    //background: -webkit-linear-gradient(rgba(29, 38, 113, 0.8)), 
    
  }
});

export default function ResponsiveGrid() {
  const classes = useStyles();
  
  return (
    //<Box sx={{ flexGrow: 1 }}>
    //<Box sx={{ '& button': { m: 2 },backgroundColor:'white',backgroundRepeat:'no-repeat',display:"flex",justifyContent:'center',alignItems:'center',}}>
    
    <Box sx={{ '& button': { m: 2 },marginInline:'auto',backgroundColor:'white',backgroundRepeat:'no-repeat',display:"flex",justifyContent:'center',alignItems:'center', minWidth: '98vw',padding:'max(3vh, 1rem)1.5rem' }}>
    <Grid container  columns={{ xs: 12, sm: 12, md: 12 ,lg:12}}>
        {Pic.map((Pic,index) => (
          <Grid sx ={{ colorFilter :" ColorFilter.tint(Color.Green, blendMode = BlendMode.Darken)",marginInline:'auto',maxHeight:'100vh',maxWidth:'100vw',display:"flex",justifyContent:'center',alignItems:'center',margin:'2rm',padding:'2rem'}} item xs={12} sm={12} md={4} key={index}>
             <Card sx ={{maxWidth: '300rem',marginInline:'auto'}}>
              
              
                <CardMedia
                  
                  //className={classes.img}
                  sx={{
                    marginInline:'auto'
                 
                  }}
                  component="img"
                  //border='2rem'
                  alt="Contemplative Reptile"
                 
                 
                  image={Pic.imageUrl}
                  //colorFilter =" ColorFilter.tint(Color.Green, blendMode = BlendMode.Darken)"
                  title="Contemplative Reptile"
                  
                />
                
               
                  <Box sx={{padding:'2rem'}}>
                  <Typography sx={{backgroundColor:'gray'}} gutterBottom variant="h5" component="h2">
                    Lizard
                  </Typography>
                  </Box>
                
              
             
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
