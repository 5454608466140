import React from 'react';
import {
	ThemeProvider,
	createTheme
} from '@mui/material/styles';
import { withStyles } from '@mui/styles';
import {
	BrowserRouter as Router,
	Routes,
	Route
} from "react-router-dom";

import Layout from './components/layout';
import Blogs from './components/blogs';
import Videos from './components/videos';
import Reviews from './components/reviewsPage';
import Individual from './components/blogs/Individual';
import IndividualVideos from './components/videos/Individual';
import CssBaseline from '@mui/material/CssBaseline';

const theme = createTheme({
	palette: {
		primary: {
			main: '#fafafa', //lightest for the button color in footer, kind of whitish
			dark: '#01579b',  //darkest blue
			contrastText: '#b3e5fc' // light blue for background here and there
		},
		secondary: {
			main: '#efebe9', //whitish greyish
			dark: '#212121', //footer color(black)
			contrastText: 'White' //light blue
		},
	},
	typography: {
		fontFamily: "Lucida Console",
		useNextVariants: true,
	},
});

const styles = {
	'@global': {
		'html, body, #root': {
			//width: '100%',
			//maxWidth: '100vw',
			//height: 'auto',
			minHeight: '200vh',
			//overflowX: 'hidden',
			//padding: 0,
			// backgroundColor: '#efebe9',
			backgroundImage: `
			radial-gradient(at 28% 71%, hsla(198,46%,89%,1) 0px, transparent 50%),
			radial-gradient(at 53% 25%, hsla(199,72%,88%,1) 0px, transparent 50%),
			radial-gradient(at 20% 23%, hsla(202,37%,78%,1) 0px, transparent 50%),
			radial-gradient(at 86% 74%, hsla(202,44%,90%,1) 0px, transparent 50%)`,
			//backgroundColor: 'rgb(160,204,246)',
			backgroundColor:'Gray'
			// background: 'radial-gradient(circle, rgba(160,204,246,1) 0%, rgba(242,245,250,1) 100%)',
		},
	},
};

const App = () => {
	return (
		<Router>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<Routes>
					<Route exact path="/" element={<Layout />} />
					<Route path="/blogs" element={<Blogs />} />
					<Route path="/videos" element={<Videos />} />
					<Route path="/reviews" element={<Reviews />} />
					<Route path="/blogs/:slug" element={<Individual />} />
					<Route path="/videos/:slug" element={<IndividualVideos />} />
					{/* <Route path="/videos" element={<LayoutAlternate />} /> */}
				</Routes>
			</ThemeProvider>
		</Router>
	)
};

export default withStyles(styles)(App);
