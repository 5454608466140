import React, {
     useState
} from 'react';
import {
     Carousel
 } from 'react-responsive-carousel';
import {
    makeStyles
} from '@mui/styles';
 //import Cover from '../../assets/HomeCover.png'
 import "./Style.css";
// import FacePRP from '../../assets/FacePRP.png';
 //import HairPRP from '../../assets/HairPRP.png';
 //import Psoriasis from '../../assets/Psoriasis.png';
 import SkinProblems from '../../assets/SkinProblems.png';
 import SkinRejuvenation from '../../assets/SkinRejuvenation.png';
 import Vitiligo from '../../assets/Vitiligo.png';
 //import Derm1 from '../../assets/slider/Derm1.jpg';
 import Derm2 from '../../assets/slider/Derm2.jpg';
 
 import Derm3 from '../../assets/slider/Derm3.jpg';
 import Test1 from '../../assets/slider/Test1.jpg';

const useStyles = makeStyles(
    theme => (
        {
            root: {
                 //display: 'flex',
                 //justifyContent:'center',
                // alignItems:'center'
                //marginTop: 60,
                //position: 'relative',
               //maxWidth: '285',
               //position:'relative',
                //height: 'auto',
               // height: '90vh',
                //maxHeight: '100vh',
                //minWidth:'90vw',
                //overflowX: 'hidden',

                // background: theme.palette.secondary.dark,
                // [theme.breakpoints.down('lg')]: {
                //     marginTop: 3,
                //     height: '80vh'
                // },              // paddingBottom: 
               // [theme.breakpoints.down('lg')]: {
                    //marginTop: 60,
                   // minHeight: '50vh',
               // },
              /* [theme.breakpoints.down('sm')]: {
                marginTop: '0.003rem',
                //minHeight: '20vh',
                 maxWidth:'100vw',
               },
               [theme.breakpoints.down('xs')]: {
                marginTop: '0.003rem',
                //minHeight: '20vh',
                 maxWidth:'100vw',
               },

               [theme.breakpoints.down('lg')]: {
                marginTop: '5rem',
                //minHeight: '50vh',
                 maxWidth:'80vw',
                 paddingLeft:'30vw',
               },

               [theme.breakpoints.down('md')]: {
                marginTop: '5rem',
                //minHeight: '50vh',
                 maxWidth:'80vw',
                 paddingLeft:'60vw',
               },*/
           
               
            },
            slide: {
                display: 'block',
                //margin: '0 auto',

                // padding: 5,
               // height: 'auto',
                //overflow: 'hidden',
                //textAlign: 'center',
                //position: 'relative',
                //backgroundColor: 'rgb(160,204,246)',
                background: 'radial-gradient(circle, rgba(160,204,246,1) 0%, rgba(242,245,250,1) 100%)',
               // width: 'auto', // Overrides inline-style
                //height: '80%',
               // '&:hover': {
                    //zIndex: 1,
                   // '& $imageTitle': {
                        //border: '4px solid currentColor',
                    //},
                //},
                height:'auto',

                [theme.breakpoints.down('sm')]: {
                    height: '65vh',
                    //maxWidth:'95vw',
                },
            },
            imageButton: {
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                display: 'flex',
                width: 'auto',
                height: 'auto',
                alignItems: 'center',
                justifyContent: 'center',
                color: theme.palette.common.white,
                opacity: 0.4
            },
            imageSrc: {
                //display: 'grid',
                position: 'relative',
                // filter: 'grayscale(100%) sepia(50%)',
                // '&:hover': {
                //     filter: 'brightness(50%) ',
                // },
                // transition: 'filter .8s ease-in-out',
                // borderRadius: '28px',
               // maxWidth: '100%',
                // width: 'auto',
                //maxWidth: '80vw',
               // maxHeight: '100vw',
                //textAlign: 'center',
               // margin: '0 auto',
                // padding: '5rem',
                //left: 0,
                //right: 0,
                //top: 0,
                //bottom: 0,
                //height: '100%',
                //Width:'90vw',
             height:'70vh',
                //height: 'auto',
                //width: '50vw',
               // marginLeft:'auto',
               // marginRight:'auto',
               objectFit: 'cover',
               aspectRatio:'1/1',
               //padding:'2rem'
               //padding:'1rem',
                //objectPosition: '15% 100%',
               // filter:'grayscale(100%)', will use it in ardent!
                
                //filter: 'contrast(1.08)',
                // [theme.breakpoints.up('sm')]: {
                //     objectFit: 'cover',
                //     position: 'absolute',
                // },
                // transition: 'filter 1s ease-in-out',
            },
            imageBackdrop: {
                position: 'absolute',
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
                // backgroundColor: theme.palette.secondary.main,
            },
            imageTitle: {
                position: 'relative',
                padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
                backgroundColor: theme.palette.secondary.main,
                color: theme.palette.secondary.dark,
                width: 'auto',
                opacity: 0.4,
            },
            autoswipe: {
               //width: '100%',
               maxWidth:'95vw',
                zIndex: 999,
                background: 'radial-gradient(circle, rgba(160,204,246,1) 0% rgba(242,245,250,1) 100%)',
                '& .carousel .control-dots .dot': {
                    // backgroundColor: 'blue',
                    boxShadow: 'none',
                },
                '& .control-arrow': {
                    position: 'absolute',
                    // zIndex: 999
                }
            },
            links: {
                textDecoration: 'none'
            }
        }
    )
);

 const sliderImages = [
     {
         menu: Test1,
        // path: 'sdg',
     },
     {
         menu: Derm3,
         //path: 'doctor',
     },
     {
         menu: Derm2,
         path: 'services',
     },
     {
         menu: SkinProblems,
         path: 'appointnment',
     },
     {
         menu: SkinRejuvenation,
         path: 'contact',
     },
     {
         menu: Vitiligo,
         path: 'contact',
     },
 ];

const PictureSlideShow = () => {
    const classes = useStyles();
     const [
         count,
         setCount
     ] = useState(0);

    return (
        
            <div
                style={{
                    // We position the video wrapper absolutely
                    // to the top of the parent element
                    // (the div in the `App` component).
                    // This only works because we set a `position` in `App`.
                     position: 'relative',
                    //top: 0,
                    
                    // To make sure the video is
                    // rendered underneath both banners.
                    // zIndex: -1,

                    // Flex box with these "center" styles to
                    // always center the video, both horizontally
                    // and vertically.
                   // display: 'flex',
                    //justifyContent: 'center',
                    //alignItems: 'center',

                    // 100% width/height for the horizontal/vertical
                    // alignments with flex to work nicely.
                   //me width: '60%',
                   //me height: '90%',
                   //me paddingTop:700,
                    // marginLeft:500,
                    width:'70vw',
                    //margin:'0 auto',
                    marginInline:'auto',
                
                  //minHeight: '90vh',
                   //padding:'clamp(10px, 70vw, 1500px)',
                   //paddingTop:'clamp(50px, 30vw+1px, 1500px)',
                   //paddingLeft:'mins(40vw,200px)',
                  //* paddingRight:'min(4vw,200px)',
                   //paddingLeft:'clamp(9px, 60vw, 200px)',
                   //height:'90vh',
                   


                    // Hide any overflow, in case the video sticks-out
                    // below the second banner, which can happen if
                    // the screen size gets too wide.
                    overflow: 'hidden',

                    // border: '5px solid red',
                    // Note, that using `border` increases the
                    // element's size by default, so we change
                    // that behavior with `boxSizing`.
                    // boxSizing: 'border-box',
                    // background: 'rgba(0,0,0,.4)',
                }}
            >
                {/*<video autoPlay={true} width='100%' height='auto' muted loop
                    style={{
                        // display: 'block',
                        // position: 'relative',
                        objectFit: 'contain',
                        // minWidth: '100%',
                        // maxWidth: '100%',
                        width: '100%',
                        height: '100%'
                    }}
                >
                    <source src="/Videos/Skinfinity.mp4" type="video/mp4" />
                </video>
            </div>*/}

             <Carousel
                // index={index}
                infiniteLoop
                autoPlay
                swipeable={true}
                showThumbs={false}
                showStatus={false}
                showIndicators={true}
                stopOnHover={false}
                //infiniteLoop={true}
                // dynamicHeight={true}
                onChange={() => setCount(count + 1)}
                transitionTime={2000}
                interval={5000}
               // className={classes.autoswipe}
                sx={{
                     //zIndex: 999,
                     //padding: '200px',
                    // width: '10%',
                     //height: '100%',
                }}
            >
                {
                    sliderImages.map(
                        (g, index) => (
                           // <div
                               // key={index}
                               //className={classes.slide}
                            //>
                                <img
                                    src={g.menu}
                                    alt={'slider ' + index}
                                    className={classes.imageSrc}
                                    //height='50%'
                                    //height='30vh'
                                    //width='80vw'
                                    //width='90vh'
                                />
                           // </div>
                        )
                    )
                }
            </Carousel> 
            </div>
    
    );
}

export default PictureSlideShow;