import Laser from './Laser/index';
import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { makeStyles } from '@material-ui/core/styles';
//import { Maximize } from '@mui/icons-material';
//import { ClassNames } from '@emotion/react';



const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  //padding:'min(1vh,4rem)',
  '&:not(:last-child)': {
    borderBottom: 0,
    
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '2rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  
    
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(4),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  
}));

const useStyles = makeStyles((theme) => ({
  accordStyle:{
    //backgroundColor:'Blue',
    marginTop:"clamp(0.1rem, 0.3rem, 1rem)", 
    //marginBottom:"clamp(0.1rem, 0.3rem, 1rem)"
  },
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };
  const classes = useStyles();

  return (
    <Box sx={{padding:"clamp(0.001rem, 6vw, 6rem)",display:'flex',flexDirection:'column', justifyContent:'space-evenly', alignItems:'center'}}>
      <Accordion className={classes.accordStyle} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Typography>skin and face services we provide</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{}}>
      
          <Laser/>
        
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordStyle} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
        <Typography>Hair services we provide</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{}}>
      
          <Laser/>
        
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordStyle} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
        <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
        <Typography>Laser services we provide</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{}}>
      
          <Laser/>
        
        </AccordionDetails>
      </Accordion>

      <Accordion className={classes.accordStyle} expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
        <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
        <Typography>Sexual Medicine services we provide</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{}}>
      
          <Laser/>
        
        </AccordionDetails>
      </Accordion>
     
     
    </Box>
  );
}