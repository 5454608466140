const Pic = [
    {
      imageUrl:'https://i.ibb.co/phV7Mpw/portrait-man-being-injected-his-face.jpg',
      title: 'IUT 10th ICT Fest Programming Contest',
    },

    {
      imageUrl:'https://i.ibb.co/0hwk9r1/dermatology-procedures-lifting-rejuvenation-modern-devices-healthcare.jpg',
      title: 'Criterion 2020 Round 1',
    },
    {
      imageUrl:'https://i.ibb.co/phV7Mpw/portrait-man-being-injected-his-face.jpg',
      title: 'Tough Duo, April 2022',
    },
    
    { 
      imageUrl:'https://i.ibb.co/0hwk9r1/dermatology-procedures-lifting-rejuvenation-modern-devices-healthcare.jpg',
      title: 'Cefalo SUST Inter University Programming Contest 2023',
    },

    {
      imageUrl:'https://i.ibb.co/phV7Mpw/portrait-man-being-injected-his-face.jpg',
      title: 'National High School Programming Contest 2021 (Senior)',
    },

    {
      imageUrl:'https://i.ibb.co/0hwk9r1/dermatology-procedures-lifting-rejuvenation-modern-devices-healthcare.jpg',
      title: 'National Girls\' Programming Contest 2021',
    },

    
  ];

  /*const Pic2 = [
    {
      imageUrl:' https://i.ibb.co/M939gnb/A-skin-Allergy.jpg',
      title: 'IUT 10th ICT Fest Programming Contest',
    },

    {
      imageUrl:'https://i.ibb.co/TWRPCBX/A-skin-Analysis.jpg',
      title: 'Criterion 2020 Round 1',
    },
    {
      imageUrl:'https://i.ibb.co/Q9LPVN2/A-anti-Aging.jpg',
      title: 'Tough Duo, April 2022',
    },
    
    { 
      imageUrl:'https://i.ibb.co/0hwk9r1/dermatology-procedures-lifting-rejuvenation-modern-devices-healthcare.jpg',
      title: 'Cefalo SUST Inter University Programming Contest 2023',
    },

    {
      imageUrl:'https://i.ibb.co/phV7Mpw/portrait-man-being-injected-his-face.jpg',
      title: 'National High School Programming Contest 2021 (Senior)',
    },

    {
      imageUrl:'https://i.ibb.co/0hwk9r1/dermatology-procedures-lifting-rejuvenation-modern-devices-healthcare.jpg',
      title: 'National Girls\' Programming Contest 2021',
    },

    
  ];

  */
  
  export default Pic;
  