import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { /*AppBar, IconButton, Toolbar,*/ Collapse } from '@material-ui/core';




const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '90vh',
    fontFamily: 'Nunito',
    
  },
  appbar: {
    background: 'none',
    display:'flex',
    justifyContent:'space-between',
    //paddingRight:2,
    //height:2,
    //padding:8,
    //backgroundcolor:'pink',
  },
 
  contactText: {
    flexGrow: '1',
    color:'#0d2b67',
    fontSize: 20,
    
  },
  icon: {
    color: 'Gray',
    fontSize: '2rem',
  },
  colorText: {
   // color: '#5AFF3D',#f5ae62
   color: '#f5ae62',
   //fontSize: 60,
   fontSize: "clamp(2rem, 2.5vw, 4rem)",
   flexWrap:'no-wrap',
   //backgroundColor:'pink',
   paddingTop:'.02rem',
   marginTop:'rem',

  },

  colorText1: {
    // color: '#5AFF3D',#f5ae62
    //fontSize: "clamp(1rem, 1.8vw, 1.4rem)",
    fontSize: "clamp(1rem, 2.5vw, 2rem)",
    //color: '#59706f',
    color:'blue',
    //fontSize: 22,
    fontFamily: 'Nunito',
    fontWeight:'bold',
    paddingLeft:'5rem',
    //backgroundColor:'pink',
    
   },
  container: {
    textAlign: 'center',
    //opacity:0,
  },
  title: {
    //color: '#EA9E82',
    color:'#4cabb0',
    //fontSize: '4.5rem',
    marginTop:'20vh',
    fontSize: "clamp(2.5rem, 4vw, 6rem)",
    opacity:1,
    background: 'transparent',
  },
  goDown: {
    color: '#5AFF3',
    fontSize: '4rem',
  },
}));
export default function Header() {
  const classes = useStyles();
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    setChecked(true);
  }, []);
  return (
    <div className={classes.root} id="header">
      

      <Collapse
        in={checked}
        {...(checked ? { timeout: 1000 } : {})}
        collapsedHeight={50}
      >
        <div className={classes.container}>
          <h1 className={classes.title}>
            Welcome to Skinfinity<br />
            <span className={classes.colorText}>For appointments and information please call us on 01711240969</span>
          </h1>
          
        </div>
      </Collapse>
    </div>
  );
}