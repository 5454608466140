import React, {
    // useState
} from 'react';
// import {
//     Carousel
// } from 'react-responsive-carousel';
import {
    makeStyles
} from '@mui/styles';
import { autocompleteClasses, Box, Grid, Paper,useTheme } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import TimelineIcon from '@mui/icons-material/Timeline';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import { green, cyan} from '@mui/material/colors';
import Icon from '@mui/material/Icon';
// import Pioneer from '../../assets/home/Pioneer.svg';
// import WorkWithRigor from '../../assets/home/WorkWithRigor.svg';
// import Experienced from '../../assets/home/Experienced.svg';
// import SevenYears from '../../assets/home/SevenYears.svg';
// import CategoryIcon from '@mui/icons-material/Category';
// import "./Style.css";
import Banner from '../banner';
import data from '../../assets/data/Workflow';

const useStyles = makeStyles(
    theme => (
        {
            root: {
                //display: 'flex',
                //flexGrow:1,
                //flexDirection:'row',
               //rowGap:'5px',
                //justifyContent:"space-between",
                //marginTop: 5,
                //marginInline:'auto',
                //position: 'relative',
              // width: '100%',
                //margin:'3rem',
                //maxWidth:'100vw',
               //height: '40vh',
               //minHeight: '50vh',
                //maxHeight: 'auto',
                //overflow: 'hidden',
                // background: theme.palette.secondary.dark,
                /* [theme.breakpoints.down('lg')]: {
                    marginTop: 3,
                    height: '50vh',
                    //width:'97vw',
                 },  */            // paddingBottom: 5,
               
            },
            paper: {

               
                
                flexDirection:'column',
               // columnGap:'50px',
                //justifyContent:"center",
                alignItems:'center',
                //height: '40vh',
               //width: '20vw',
               //margin:'1rem',
               //padding:'1rem',
               //boxSizisng:'border-box',

               
                minHeight: '180px',
                // padding: theme.spacing(1),
                // color: '#3e2723',
                '&:hover': {
                    transform: 'scale(1.1)',
                    boxShadow: '0px 5px 3px -3px #3e2723'
                },
                //backgroundColor: theme.palette.primary.contrastText,
                //backgroundColor: 'blue',
                color: theme.palette.primary.dark,
                // backgroundImage: `
                // radial-gradient(at 77% 24%, hsla(199,72%,88%,1) 0px, transparent 50%),
                // radial-gradient(at 17% 65%, hsla(202,36%,78%,1) 0px, transparent 50%)`,
                // backgroundColor: '#bbdefb',
                // transition: 'transform 0.8s easeInOut',
                // MozTransition: 'transform2s ease-in-out',
                // OTransition: 'transform 2s ease-in-out',
                // transition: 'transform 2s ease-in-out'
            },
            control: {
                padding: theme.spacing(1),
            },
            icon1: {
                // fontSize: '50px',
                //backgroundColor:'pink',
                
                margin: '25px auto',
                //padding: '1px',
                width: '50px',
                height:'50px',
                display:'flex',
                flexDirection:'row',
                justifyContent:'center',
               // columnGap:'50px',
                //justifyContent:"center",
                alignItems:'center',


               marginInline:'auto',
                //height: '8vh',
                //maxHeight:'80px',
                //boxSizing:'border-box',
                backgroundColor:'gray',
            },

            icon:{ 
                        color: cyan[600], 
                        padding:'5px',
                        display:'flex', 
                        flexDirection:'row', 
                        justifyContent:'center',
                        alignItems:'center' , 
                        marginInline:'auto',
                        height:'80px',
                        width:'80px',
                        fontSize: 60
                    }
        }
    )
);

const HomeTwo = () => {
    const classes = useStyles();
    const theme = useTheme();
    // const [
    //     count,
    //     setCount
    // ] = useState(0);

    return (
        /*<Grid
            container
            direction="row"
            //justifyContent="space-between"
            //alignItems="center"
            spacing={2}
            className={classes.root}
        >*/
            
           <Grid
                container
                justifyContent='center'
                alignItems='center'
                spacing={2}
            	sx={{
                	margin: '10px auto',
                    //marginInline:'auto',
                    //backgroundColor:'gray',
                	padding:'4rem',
                	width:'90vw',
                  //height:'40vh',
                  //padding:'40px'
                }}
            >
                {
                    data.map(
                        (
                            i, index
                        ) => {
                            // var Icon = i.Icon;
                            return (
                                <Grid
                                
                                    key={index}
                                    item
                                    xs={12}
                                    sm={6}
                                    md={3}
                                    lg={3}
                                    //spacing={6}
                                    
                                    sx={{
                                        //display:'flex',
                                        //flexDirection:'column-reverse',
                                        //justifyContent:'center',
                                        //alignItems:"center",
                                        margin: '15px auto',
                                        //paddingLeft:'3rem',
                                        //paddingRight:'3rem',
                                        //backgroundColor:'pink',
                                        marginInline:'auto',
                                    
                                        //height:'auto',
                                        //height:'40vh',     
                                    }}
                                >
                                    <Card
                                        elevation={1}
                                        sx={{margin:'2rem',height:'250px', }}
                                        
                                    >           
                                           {/* <img src={i.icon} alt={i.title} className={classes.icon} />*/}
                                        <Box sx={{padding:'3px', margin:'20px'}}>
											{index == 0 && <WorkspacePremiumIcon 
												sx={
													{ 
														color: cyan[600], 
														padding:'5px',
														display:'flex', 
														flexDirection:'row', 
														justifyContent:'center',
														alignItems:'center', 
														marginInline:'auto',
														height:'80px',
														width:'80px',
														fontSize: 60,
													}
												}
											/>
											}
											{index == 1 && <HealthAndSafetyIcon
												sx={
													{ 
														color: cyan[600], 
														padding:'5px',
														display:'flex', 
														flexDirection:'row', 
														justifyContent:'center',
														alignItems:'center', 
														marginInline:'auto',
														height:'80px',
														width:'80px',
														fontSize: 60,
													}
												}
											/>
											}
											{index == 2 && <MedicalInformationOutlinedIcon
												sx={
													{ 
														color: cyan[600], 
														padding:'5px',
														display:'flex', 
														flexDirection:'row', 
														justifyContent:'center',
														alignItems:'center', 
														marginInline:'auto',
														height:'80px',
														width:'80px',
														fontSize: 60,
													}
												}
											/>
											}	
											{index == 3 && <TimelineIcon
												sx={
													{ 
														color: cyan[600], 
														padding:'5px',
														display:'flex', 
														flexDirection:'row', 
														justifyContent:'center',
														alignItems:'center', 
														marginInline:'auto',
														height:'80px',
														width:'80px',
														fontSize: 60,
													}
												}
											/>
											}										
                                    	</Box>
                                            <CardContent sx={{}}>
                                                <Banner
                                                title={i.title}
                                                variant='h6'
                                                //flag='0'
                                                sx={{
                                                   padding:'0px' ,marginInline:'auto'
                                                }}
                                            />
                                       
                                            </CardContent>   
                                    </Card>
                                </Grid>
                            )
                        }
                    )
                }
            </Grid>
        /*</Grid> */  

        
    );
}

export default HomeTwo;