import React from 'react';
import {
    Typography
} from '@mui/material';
import {
    makeStyles
} from '@mui/styles';

const useStyles = makeStyles(
    theme => (
        {
            root1: {
                //color: theme.palette.secondary.dark,
                padding: theme.spacing(2),
                height: 'auto',
                backgroundColor: 'transparent',
                margin: '0 auto',
                width: '100%',
                
            },
            icon: {
                fontSize: '50px',
                //color: theme.palette.secondary.dark,
                verticalAlign: 'middle',
                position: 'static',
                margin: '0 auto'
            },
        }
    )
);

const Banner = ({
    variant,
    title,
    ...props
}) => {
    const classes = useStyles();

    return (
        <Typography
            variant={variant}
            color="inherit"
            className={classes.root1}
            align="center"
            {...props}
        >
            {title}
        </Typography>
    )
}


export default Banner;